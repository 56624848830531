<template>
  <div id="bPay">
    <div class="content_box">
      <div class="inner_max">
        <div class="form_deposit" v-if="formFlag">
          <h4>{{ $t('deposit.bPay.header') }}</h4>
          <div class="content">
            <div class="info_box">
              <img src="@/assets/images/channel/bpay.png" alt />
              <p>{{ $t('deposit.bPay.option') }}</p>
              <ul>
                <li>{{ $t('deposit.bPay.inst1') }}</li>
                <li>{{ $t('deposit.bPay.inst2') }}</li>
                <li>{{ $t('deposit.bPay.inst3') }}</li>
              </ul>
            </div>
            <div class="form_main">
              <p class="title">{{ $t('deposit.bPay.form.header') }}</p>
              <el-form label-position="top" :model="bpayForm" ref="bpayForm" :rules="bpayRules" status-icon>
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('common.field.accNum')" prop="account">
                        <el-select v-model="bpayForm.account" data-testid="account">
                          <el-option
                            v-for="item in bpayForm.accountNumberOptions"
                            :key="item.value"
                            :value="JSON.stringify(item.data)"
                            :label="item.value"
                            :data-testid="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('deposit.bPay.form.billerCode')">
                        <el-input v-model="bpayForm.bpayCode" :readonly="true" data-testid="bpayCode"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('deposit.bPay.form.refNum')">
                        <el-input v-model="bpayForm.bpayNumber" :readonly="true" data-testid="bpayNumber"></el-input>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="bpayForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('deposit.bPay.form.receipt')" class="upload">
                        <vUpload :limit="6" v-on:updateFileInfo="updateFileInfo" data-testid="updateFileInfo"></vUpload>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="bpayForm.applicationNotes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                </div>
                <div class="info">
                  <i18n path="deposit.default.alt" tag="p">
                    <template v-slot:mailTo>
                      <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
                    </template>
                  </i18n>
                </div>

                <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
        <Result v-if="successFlag">
          <p v-if="fileList.length <= 0">
            <i18n path="deposit.bPay.form.reminderNotUploaded" tag="div">
              <template v-slot:mailTo>
                <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
              </template>
            </i18n>
          </p>
          <p class="last" v-if="fileList.length <= 0">{{ $t('deposit.bPay.form.reminderProc') }}</p>
          <p v-if="fileList.length > 0">{{ $t('deposit.default.reminderFundAppear') }}</p>
        </Result>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import mixin from '@/mixins/page/deposit';
import { apiQuery_mt_accounts, apiBpay_payment } from '@/resource';

export default {
  name: 'BPayDeposit',
  components: { NumericInput, vUpload, Result },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };
    return {
      bpayForm: {
        account: '',
        mt4Account: '',
        accountNumberOptions: [],
        bpayCode: '228494',
        bpayNumber: '',
        amount: '',
        applicationNotes: ''
      },
      bpayRules: {
        account: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      fileList: [],
      formFlag: true,
      successFlag: false
    };
  },
  watch: {
    'bpayForm.account'(account) {
      this.bpayForm.mt4Account = JSON.parse(account).accountNumber;
      this.bpayForm.bpayNumber = JSON.parse(account).bpayNumber;
      this.accountCurrency = JSON.parse(account).currency;
    },
    'bpayForm.accountNumberOptions'(options) {
      // 預設第一筆
      if (options.length > 0) this.bpayForm.account = JSON.stringify(options[0].data);
    }
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
    },
    submitForm() {
      this.$refs['bpayForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data) {
                this.formFlag = false;
                this.successFlag = true;
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    fetchTradingAccount() {
      apiQuery_mt_accounts({ supportedCurrencies: 'AUD' }).then(resp => {
        if (resp.data.code == 0) {
          this.bpayForm.accountNumberOptions = resp.data.data.map(i => {
            return {
              value: i.mt4_account + ' - ' + i.currency,
              currency: i.currency,
              data: {
                accountNumber: i.mt4_account,
                bpayNumber: i.bpayNumber,
                currency: i.currency
              }
            };
          });
        }
      });
    },
    submitDeposit() {
      return apiBpay_payment(
        {
          //payment channel is 5, payment type is 2
          mt4Account: this.bpayForm.mt4Account,
          operateAmount: this.bpayForm.amount,
          applicationNotes: this.bpayForm.applicationNotes,
          fileList: this.fileList
        },
        this.token
      );
    }
  },
  mounted() {
    this.fetchTradingAccount();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
